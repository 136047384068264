<template>
<div>
	<hr />
	<div class="container">
		<div class="columns is-centered">
			<div class="column is-offset-1"></div>
			<div class="column is-10">
				<b-tabs>
			    <b-tab-item label="Soilgrids" icon="globe">  			    
				<div class="tile is-ancestor" v-for="row in sg_rows" v-bind:key="row.id">
					<!--  https://stackoerflow.com/questions/40029856/nested-lo op-in-vue -->
					<!--  to messy with loops -->
					<div class="tile is-vertical is-parent" v-for = "col in row" v-bind:key = "col.id" >
						<div class="tile is-child box">
							<p class="title has-text-left">{{ col.title }}</p>
							<p class="subtitle has-text-left">{{ col.subtitle }}</p>
							<p class="has-text-left">
								<b-tooltip label="Mapserver file (for reference)" 
								size="is-small" type="is-light" :position="col.position" multilined><code>mapfile: {{ col.mapfile }}</code></b-tooltip>
							</p>
							<p class="has-text-left">
							<b-tooltip label="Link for WMS or WCS service. Use it in QGIS or ArcGIS" 
							size="is-small" type="is-light" :position="col.position" multilined><code >WxS link: <a :href="url_mapserver+col.mapfile" target="_blank">{{ url_mapserver }}{{ col.mapfile }}</a></code></b-tooltip>
							</p>
							<p class="has-text-left">
							<b-tooltip label="Fast layer display" 
							size="is-small" type="is-light" :position="col.position" multilined><code>Openlayers display for: <a :href="url_mapserver+col.mapfile+'&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&BBOX=-19948500.0,-6147449.0951699,19688885.757062882,8361000.0&CRS=EPSG:152160&WIDTH=1426&HEIGHT=895&LAYERS=' + col.layer + '&STYLES=&FORMAT=application/openlayers'" target="_blank">{{ col.layer }}</a></code></b-tooltip>
							</p>
						</div><!-- class="tile is-child box"  -->
					</div> <!-- end of is-vertical is-parent -->
				</div>  <!-- end ancestor -->
				</b-tab-item>
				<b-tab-item label="Vector Features" icon="draw-polygon" >
					 <div class="tile is-ancestor" v-for="row in feature_rows" v-bind:key="row.id">
					<!--  https://stackoerflow.com/questions/40029856/nested-lo op-in-vue -->
					<!--  to messy with loops -->
					<div class="tile is-vertical is-parent" v-for = "col in row" v-bind:key = "col.id" >
						<div class="tile is-child box">
							<p class="title has-text-left">{{ col.title }}</p>
							<p class="subtitle has-text-left">{{ col.subtitle }}</p>
							<p class="has-text-left">
								<b-tooltip label="Mapserver file (for reference)" 
								size="is-small" type="is-light" :position="col.position" multilined><code>mapfile: {{ col.mapfile }}</code></b-tooltip>
							</p>
							<p class="has-text-left">
							<b-tooltip label="Link for WFS service. Use it in QGIS or ArcGIS" 
							size="is-small" type="is-light" :position="col.position" multilined><code >WFS link: <a :href="url_mapserver+col.mapfile" target="_blank">{{ url_mapserver }}{{ col.mapfile }}</a></code></b-tooltip>
							</p>
						</div><!-- class="tile is-child box"  -->
					</div> <!-- end of is-vertical is-parent -->
				</div>  <!-- end ancestor -->
				</b-tab-item>
				<b-tab-item label="Rasters" icon="globe-africa" >
				<div class="tile is-ancestor" v-for="row in raster_rows" v-bind:key="row.id">
					<!--  https://stackoerflow.com/questions/40029856/nested-lo op-in-vue -->
					<!--  to messy with loops -->
					<div class="tile is-vertical is-parent" v-for = "col in row" v-bind:key = "col.id" >
						<div class="tile is-child box">
							<p class="title has-text-left">{{ col.title }}</p>
							<p class="subtitle has-text-left">{{ col.subtitle }}</p>
							<p class="has-text-left">
								<b-tooltip label="Mapserver file (for reference)" 
								size="is-small" type="is-light" :position="col.position" multilined><code>mapfile: {{ col.mapfile }}</code></b-tooltip>
							</p>
							<p class="has-text-left">
							<b-tooltip label="Link for WMS or WCS service. Use it in into QGIS or ArcGIS" 
							size="is-small" type="is-light" :position="col.position" multilined><code >WxS link: <a :href="url_mapserver+col.mapfile" target="_blank">{{ url_mapserver }}{{ col.mapfile }}</a></code></b-tooltip>
							</p>
							<p class="has-text-left">
							
							<b-tooltip label="Fast layer display" 
							size="is-small" type="is-light" :position="col.position" multilined><code>Openlayers display for: <a :href="url_mapserver+col.mapfile+'&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&BBOX=-40,-50,55,15&CRS=EPSG:4326&WIDTH=1426&HEIGHT=895&LAYERS=' + col.layer + '&STYLES=&FORMAT=application/openlayers'" target="_blank">{{ col.layer }}</a></code></b-tooltip>
							</p>
						</div><!-- class="tile is-child box"  -->
					</div> <!-- end of is-vertical is-parent -->
				</div>  <!-- end ancestor -->
				</b-tab-item>
				
				
				</b-tabs>	
			</div>
			<div class="column is-offset-1"></div>
		</div>
	</div>
</div>
</template>

<script>

/*eslint no-mixed-spaces-and-tabs: "off"*/
export default {
  name: 'Index',
  computed: {
	  url_mapserver: function(){
		  if (process.env.NODE_ENV === "development") {
		  	return "https://dev-maps.isric.org/mapserv?map=/map/"	  
	  	  } else {
		  	return "https://maps.isric.org/mapserv?map=/map/"	
	  	  }
	  }
  },
  data: function() {
   	  return {
   		 activeTab: 1,
/*       url_mapserver: "https://maps.isric.org/mapserv?map=/map/", */
	     sg_rows: [
		 	 [{"id":0, "title":"wrb","subtitle":"WRB classes and probabilites","mapfile":"wrb.map","layer":"MostProbable","position":"is-left"},{"id":1, "title":"bdod","subtitle":"Bulk density","mapfile":"bdod.map","layer":"bdod_0-5cm_mean","position":"is-right"}],
	    	 [{"id":2,"title":"cec","subtitle":"Cation exchange capacity at ph 7","mapfile":"cec.map","layer":"cec_0-5cm_mean","position":"is-left"},{"id":3, "title":"cfvo","subtitle":"Coarse fragments volumetric","mapfile":"cfvo.map", "layer":"cfvo_0-5cm_mean","position":"is-right"}],
		  	 [{"id":4,"title":"clay","subtitle":"Clay content","mapfile":"clay.map","layer":"clay_0-5cm_mean","position":"is-left"},{"id":5, "title":"nitrogen","subtitle":"Nitrogen","mapfile":"nitrogen.map","layer":"nitrogen_0-5cm_mean","position":"is-right"}],
		  	 [{"id":6,"title":"phh2o","subtitle":"Soil pH in H2O","mapfile":"phh2o.map","layer":"phh2o_0-5cm_mean","position":"is-left"},{"id":7, "title":"sand", "subtitle":"Sand content","mapfile":"sand.map","layer":"sand_0-5cm_mean","position":"is-right"}],
		  	 [{"id":8,"title":"silt","subtitle":"Silt content","mapfile":"silt.map","layer":"silt_0-5cm_mean","position":"is-left"},{"id":9, "title":"soc", "subtitle":"Soil organic carbon content","mapfile":"soc.map","layer":"soc_0-5cm_mean","position":"is-right"}],
		     [{"id":10,"title":"ocs","subtitle":"Soil organic carbon stock","mapfile":"ocs.map","layer":"ocs_0-30cm_mean","position":"is-left"},{"id":11, "title":"ocd", "subtitle":"Organic carbon densities","mapfile":"ocd.map","layer":"ocd_0-5cm_mean","position":"is-right"}],
			 [{"id":12,"title":"wv1500","subtitle":"Water Layer wv1500","mapfile":"wv1500.map","layer":"wv1500_0-5cm_mean","position":"is-left"},{"id":13, "title":"wv0033", "subtitle":"Water Layer wv0033","mapfile":"wv0033.map","layer":"wv0033_0-5cm_mean","position":"is-right"},{"id":14,"title":"wv0010","subtitle":"Water Layer wv0010","mapfile":"wv0010.map","layer":"wv0010_0-5cm_mean","position":"is-left"}],
		  ],
	  feature_rows: [
		  [{"id":1, "title":"wosis_latest", "subtitle":"Latest WOSIS data", "mapfile":"wosis_latest.map","position":"is-left"},{"id":2,"title": "external","subtitle": "Non-ISRIC datasets", "mapfile":"external.map","position":"is-right"}],
		  ],
	  raster_rows: [
		  [{"id":1, "title": "gyga","subtitle":"AfSIS-GYGA functional soil information for Sub-Saharan Africa (RZ-PAWHC-SSA)","mapfile":"gyga.map","layer": "gyga_af_agg_erzd_crfvol__m_1km", "position":"is-left"},{"id":"2","title":"af250m_nutrient", "subtitle":"Africa SoilGrids nutrients","mapfile":"af250m_nutrient.map","layer": "af250m_nutrient_n_m_agg30cm",  "position":"is-right"}]
	  ],  
   	  } //end return
  		},
  } //export default
</script>


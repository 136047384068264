<template>
  <div id="app">
  <vue-headful title="Mapserver entry page" description="Support page for mapserver access points" keywords="mapserver, ISRIC, soil data, soil, soilgrids, WOSIS, WISE, soter" image="" lang="en" url="http://maps.isric.org" />
     <div class="container">
		<div class="columns is-centered">
			<div class="column is-narrow has-text-centered">
				<figure class="image is-inline-block has-text-centered">
					<img src="./assets/logo_isric.svg" />
				</figure>
			</div>
		
		</div>
	</div>
	<p>&nbsp;&nbsp;</p>
	<div class="container">
		<h1 class="title has-text-centered">Mapserver entry page</h1>
		<p></p>
		
		<div class="container">
			<div class="columns is-centered">
				<div class="column is-offset-3"></div>
				
				<div class="column is-6 has-text-justified">
					<div class="has-text-justified"><p>Mapserver provides OGC web-services that can be used to <strong>pull soil data directly from ISRIC servers</strong> (Please check <a href="https://isric.org/about/data-policy" target="_blank">ISRIC's data policy, disclaimer and citation</a>).</p>
					<br />
					<p>GIS softwares like <a href="https://qgis.org" target="_blank">QGIS</a> and ArcGIS can be used to download data for specific areas, together with links bellow indicated.</p> 
					<br />
					For data citation and license, please consult our: <a href="https://www.isric.org/about/data-policy" target="_blank">Data and Software Policy</a>
					<br />
					<br />
					<p><strong>Please check the ISRIC tutorial on how to access layers: </strong></p>
					<p><strong>WMS: </strong><a href="https://www.isric.org/instruction-wms" target="_blank">https://www.isric.org/instruction-wms</a></p>
					<p><strong>WCS: </strong><a href="https://www.isric.org/web-coverage-services-wcs" target="_blank">https://www.isric.org/web-coverage-services-wcs</a></p>
					<br />
					<p><strong>For soilgrids FAQ and information: </strong><a href="https://www.isric.org/explore/soilgrids/faq-soilgrids" target="_blank">Soilgrids FAQ</a></p> 
					
                    <strong>Problems ?! <a href="#" data-contact="c29pbGdyaWRzQGlzcmljLm9yZw==" data-subj="bWFwc2VydmVyIHByb2JsZW0=" onfocus="this.href = 'mailto:' + atob(this.dataset.contact) + '?subject=' + atob(this.dataset.subj || '')">Contact us</a></strong>
					</div>
				</div>
				
				<div class="column is-offset-3"></div>
			</div>
		</div>

	
	</div>
	
    <MapCards/>
  </div>
</template>

<script>
import MapCards from './components/MapCards.vue'
export default {
  name: 'app',  
  components: {
    MapCards,
  }
}
</script>



<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

a {
	color: #42b983;
}

.tabs a {
	color: #f07f3c;
}

.tabs li.is-active a {
	color: #8b2822;
	border-bottom-color: #8b2822;
}

</style>
